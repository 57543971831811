import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box, Dialog, DialogTitle } from '@mui/material';
import Headline from '../Headline/Headline';
import Startseite from '../Sites/Startseite';
import Footer from '../Headline/Footer';
import AppRouter from '../routes/AppRouter';
import { BrowserRouter } from 'react-router-dom';

function PopUpInfo({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) {

  const handleClose = () => {
    setOpen(false);
  }

  return (
  <Dialog onClose={handleClose} open={open} sx={{}}>
    <Box sx={{height:36.5, backgroundColor: 'rgb(234, 191, 64)', width:'100%', display:'flex',flexDirection:'row', }}>
      <Box sx={{flex:1}}></Box>
      <IconButton onClick={handleClose} sx={{}}>
        <CloseIcon></CloseIcon>
      </IconButton>
    </Box>
    <Box sx={{display:'flex',flexDirection:'column',  p:2, hyphens: 'auto',	textAlign: 'justify', maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden'}}>
      {/* <DialogTitle>Ankündigung</DialogTitle> */}
      <p>Liebe Patientinnen und Patienten!</p>
      <h3>Zum 1. April 2025 gibt es in unserer Praxis Veränderungen:</h3>
      <p>Als <b>Nachfolger</b> übernimmt <b>Dr. Lupu</b> als Internist mit jahrelanger beruflicher Erfahrung ab dem 1. April 2025 Ihre Betreuung und Weiterbehandlung <b>als  Hausarzt im Kelterweg</b>.</p>
      
      <p>Frau <b>Dr. Winkler</b> verlegt ihren Praxissitz in die Spitzwegstraße 35 in Heilbronn-Sontheim (bei der Spitzwegapotheke) und führt dort ab dem 01. April 2025 mit Frau Dr. Czech eine hausärztliche Gemeinschaftspraxis. <b>Sie freut sich darauf, Sie dort weiterzubetreuen.</b></p>
    
      <p>Frau <b>Dr. Stähle</b> beendet ihre Tätigkeit im Kelterweg.</p>
     
      <p>Vielen Dank, dass wir Sie in den vergangenen 14 Jahren in Horkeim als unsere Patientinnen und Patienten betreuen durften.</p>
 
      <p>Dr. Stähle & Dr. Winkler</p>

</Box>
      <Button 
      sx={{
        ":hover":{backgroundColor:'rgb(234, 191, 64)',
          fontWeight:'bold'
        },
        backgroundColor:'rgb(234, 191, 64)',
        color:'#505050'
      }} 
      onClick={handleClose}>Schließen</Button>
      
    </Dialog>
  )

}



function App() {
  // const [onlineStat,setOnlineStat] = useState(false);

  const [open, setOpen] = useState(false);
  const [popUpAlreadyShown, setPopUpAlreadyShown] = useState(false);

  useEffect(() => {
    if (!popUpAlreadyShown) {
      setOpen(true);
      setPopUpAlreadyShown(true);
    }
  }, [popUpAlreadyShown]);

  return (
    <>
      <BrowserRouter>
        <Box sx={{
          // height:'100vh',
          width: '100vw', minHeight: '100vh', backgroundColor: '#dddddd', display: 'flex', flexDirection: 'column'
        }}>
          <Box sx={{display:'flex',flexDirection:'column', flex: 1, ml: { md: 'auto', xs: 0 }, mr: { md: 'auto', xs: 0 }, width: { lg: '70vw', xs: '100vw' }, boxShadow: '0px 0px 16px 2px rgba(0,0,0,0.96)' }}>

            <Headline></Headline>
            <PopUpInfo open={open} setOpen={setOpen}></PopUpInfo>
            <Box sx={{ display:'flex',flexDirection:'column', flex:1 }}>

              <AppRouter></AppRouter>
              <Box sx={{flex:1, display:'flex',flexDirection:'column'}}>
                <Box sx={{flex:1, backgroundColor:'white'}}></Box>
                <Footer></Footer>
              </Box>
            </Box>
            {/* Todo Footer */}
          </Box>
        </Box>
      </BrowserRouter>
    </>



  );
}

export default App;









